import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Modal from "@material-ui/core/Modal";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import { ITestsResult } from "twillio-tests/core/TestResults";
import { customTranslateText, translateText } from "../../helpers/utils";
import checkBrowser from "../../actions/browserCheck";
import { TestConfiguration } from "twillio-tests/core/testConfiguration";
import { useTranslation } from "react-i18next";
interface IBrowserSupportModalProps {
  classes?: any;
  testResult?: ITestsResult | undefined;
  theme?: any;
  textDictionary?: Record<string, string>;
  config?: TestConfiguration;
}

function mapStateToProps(state: any) {
  return {
    testResult: state.tests.testResult,
    theme: state.document.theme,
    textDictionary: state.tests.textDictionary,
    config: state.tests.config,
  };
}

const styles = (theme: any) => ({
  paper: {
    position: "absolute" as "absolute",
    maxWidth: 400,
    width: "90%",
    backgroundColor: "#fff",
    padding: "30px",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  message: {
    textAlign: "center" as "center",
  },
  close: {
    position: "absolute" as "absolute",
    bottom: "100%",
    right: 0,
    color: "#fff",
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 15,
    cursor: "pointer",
  },
  close_mend: {
    fontWeight: 500,
  },
});

const BrowserSupportModal = (props: IBrowserSupportModalProps) => {
  let [open, setOpen] = useState(false);
  const { classes, textDictionary, theme, config } = props;

  if (!textDictionary) {
    throw new Error("Missing parameter");
  }
  const { t, i18n } = useTranslation(["tests"]);
  let message: string = customTranslateText(
    t(`tests:textDictionary.${config?.theme}.not-supported-browser-modal`),
    t("tests:explanation.not-supported-browser-modal"),
    i18n.exists(`tests:textDictionary.${config?.theme}.not-supported-browser-modal`)
  );
  useEffect(() => {
    if (props.testResult?.checkBrowser) {
      setOpen(!props.testResult.checkBrowser.canRun);
    }
    if (props.testResult?.checkVersion && !open) {
      setOpen(!props.testResult.checkVersion.canRun);
    }
  }, [open, props.testResult]);
  if (props.theme === "engauge") {
    message =
      props.testResult?.checkBrowser && props.testResult?.checkBrowser.message
        ? props.testResult.checkBrowser.message
        : "";
    if (config) {
      const checkBrowserResults = checkBrowser(config);
      if (!checkBrowserResults.canRun && checkBrowserResults.message) {
        open = true;
        message = checkBrowserResults.message;
      }
    }
  }

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className={classes.paper}>
        <Typography
          variant="body1"
          className={classes.message}
          dangerouslySetInnerHTML={{ __html: message }}
        ></Typography>
      </div>
    </Modal>
  );
};

export default connect(mapStateToProps, null)(withStyles(styles)(BrowserSupportModal));
