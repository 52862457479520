import React from "react";

const createArc = (
  centerX: number,
  centerY: number,
  radius: number,
  innerRadius: number,
  startAngle: number,
  endAngle: number,
  strokeWidth: number,
  gap: number
) => {
  const adjustedStartAngle = startAngle + gap / 2;
  const adjustedEndAngle = endAngle - gap / 2;

  const startOuter = {
    x: centerX + radius * Math.cos(adjustedStartAngle),
    y: centerY + radius * Math.sin(adjustedStartAngle),
  };
  const endOuter = {
    x: centerX + radius * Math.cos(adjustedEndAngle),
    y: centerY + radius * Math.sin(adjustedEndAngle),
  };
  const startInner = {
    x: centerX + innerRadius * Math.cos(adjustedEndAngle),
    y: centerY + innerRadius * Math.sin(adjustedEndAngle),
  };
  const endInner = {
    x: centerX + innerRadius * Math.cos(adjustedStartAngle),
    y: centerY + innerRadius * Math.sin(adjustedStartAngle),
  };

  const largeArcFlag = adjustedEndAngle - adjustedStartAngle <= Math.PI ? 0 : 1;
  const d = [
    `M ${startOuter.x} ${startOuter.y}`,
    `A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endOuter.x} ${endOuter.y}`,
    `L ${startInner.x} ${startInner.y}`,
    `A ${innerRadius} ${innerRadius} 0 ${largeArcFlag} 0 ${endInner.x} ${endInner.y}`,
    "Z",
  ].join(" ");

  return d;
};

const DonutSlice = ({
  centerX,
  centerY,
  radius,
  innerRadius,
  startAngle,
  endAngle,
  color,
  gap,
}: {
  centerX: number;
  centerY: number;
  radius: number;
  innerRadius: number;
  startAngle: number;
  endAngle: number;
  color: string;
  gap: number;
}) => {
  const d = createArc(centerX, centerY, radius, innerRadius, startAngle, endAngle, innerRadius - radius, gap);
  return <path d={d} fill={color} />;
};

const SlicedDonutChart = ({
  x,
  y,
  parentId,
  childId,
  themeColor,
  theme,
}: {
  x: number;
  y: number;
  parentId?: string;
  childId?: string;
  themeColor?: any;
  theme: any;
}) => {
  const total = x;
  const sliceAngle = (2 * Math.PI) / total;
  const gap = 0.02;
  const slices = Array.from({ length: total }, (_, i) => {
    const startAngle = i * sliceAngle - Math.PI / 2;
    const endAngle = startAngle + sliceAngle;
    const color = i >= total - y ? themeColor.errorColor : themeColor.successColor;
    return { startAngle, endAngle, color };
  });

  return (
    <svg width="168" height="168" id={parentId}>
      {slices.map((slice, index) => (
        <DonutSlice
          key={index}
          centerX={84}
          centerY={84}
          radius={70}
          innerRadius={60}
          startAngle={slice.startAngle}
          endAngle={slice.endAngle}
          color={slice.color}
          gap={gap}
        />
      ))}
      <text
        x="50%"
        y="50%"
        dy="-0.3em"
        textAnchor="middle"
        style={{
          fill: `${themeColor.successColor}`,
          fontFamily: theme.typography.fontFamily,
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "24px",
          letterSpacing: "0.15px",
        }}
      >
        {y === 0 ? "Connected" : "Unreachable"}
      </text>
      <text x="50%" y="50%" dy="1.7em" textAnchor="middle" id={childId}>
        {y === 0 ? (
          <tspan
            style={{
              fill: `${themeColor.successColor}`,
              fontFamily: theme.typography.fontFamily,
              fontSize: "30px",
              fontWeight: 700,
              lineHeight: "37.5px",
            }}
          >
            {x}
          </tspan>
        ) : (
          <>
            <tspan
              style={{
                fill: `${themeColor.errorColor}`,
                fontFamily: theme.typography.fontFamily,
                fontSize: "30px",
                fontWeight: 700,
                lineHeight: "37.5px",
              }}
            >
              {y}
            </tspan>
            <tspan
              style={{
                fill: `${themeColor.errorColor}`,
                fontFamily: theme.typography.fontFamily,
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "37.5px",
              }}
            >
              {" of "}
            </tspan>
            <tspan
              style={{
                fill: `${themeColor.errorColor}`,
                fontFamily: theme.typography.fontFamily,
                fontSize: "30px",
                fontWeight: 700,
                lineHeight: "37.5px",
              }}
            >
              {x}
            </tspan>
          </>
        )}
      </text>
    </svg>
  );
};

export default SlicedDonutChart;
