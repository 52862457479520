import { TestConfiguration } from "twillio-tests/core/testConfiguration";
import { getBrowserInfo } from "twillio-tests/check-browser";

export default function checkBrowser(config: TestConfiguration) {
  let canRun = true;
  const browserInfo = getBrowserInfo();
  const browserVersion = `${browserInfo.browser}-${browserInfo.version}-${browserInfo.os}`;
  if (config.browserCheckRegexp) {
    const regex = new RegExp(config.browserCheckRegexp);
    canRun = regex.test(browserVersion);
  }

  browserInfo.canRun = canRun;
  return browserInfo;
}
