import { ActionType } from "../actions/actionTypes";
import { ITestsState } from "../interfaces";

const testsInitialState: ITestsState = {
  isReady: true,
  testsList: [],
  logs: [],
  testResult: null,
  config: undefined,
  cancelable: false,
  textDictionary: {},
  email: "",
  title: "",
};

export default (state: ITestsState = testsInitialState, action: any) => {
  switch (action.type) {
    case ActionType.INIT_TESTS:
      return { ...state, isReady: true };
    case ActionType.LOG_MESSAGE: {
      const newLogs = [...state.logs];
      const { message, color, time } = action.payload;
      newLogs.push({
        message,
        color,
        time,
      });
      return { ...state, logs: newLogs };
    }
    case ActionType.UPDATE_TEST_RESULT:
      return { ...state, testResult: action.payload };
    case ActionType.GET_LAYOUT: {
      const testResult = null;
      const logs: any[] = [];
      const email = "";
      const title = "";
      const createDate = null;
      // if (action.payload.serverResults) {
      //   testResult = action.payload.serverResults.testResult;
      //   logs = action.payload.serverResults.logs;
      //   email = action.payload.serverResults.email;
      //   title = action.payload.serverResults.title;
      //   createDate = action.payload.serverResults.createDate;
      // }

      return {
        ...state,
        testResult,
        logs,
        email,
        title,
        createDate,
        ...action.payload.serverResults,

        config: action.payload.config,
        cancelable: action.payload.config.cancelable,
        textDictionary: action.payload.config.textDictionary,
        testsList: action.payload.config.testsList,
      };
    }
    case ActionType.RESET_TEST_RESULT:
      return { ...state, logs: [], testResult: null, createDate: null,scoringResult:undefined };
    default:
      return state;
  }
};
