import React, { useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import withTheme from "@material-ui/core/styles/withTheme";
import TestHeader from "../TestHeader";
import { PingIco, ChevronIco } from "../../icons";
import { RenderValues } from "../helpers/utils";
import { IColorThresholds, TestConfiguration } from "twillio-tests/core/testConfiguration";
import { formatId, formatNumber, getColorValue } from "../../../helpers/utils";
import { IPing, ITestsResult } from "twillio-tests/core/TestResults";
import { useTranslation } from "react-i18next";

interface IPingTestAllProps {
  mainColor?: string;
  testResult?: ITestsResult;
  theme?: any;
  classes?: any;
  pageSize?: number;
  colorThresholds?: IColorThresholds;
  knowledgeUrl?: string;
  estimateTime?: number;
  testName?: string;
  config?: TestConfiguration;
}

const styles = (theme: any) => ({
  slider: {
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 0",
    fontSize: 14,
    fontWeight: 400,
  },
  prev: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    "& svg": {
      width: 5,
      height: 8,
      transform: "scale(-1)",
      marginRight: 5,
    },
  },
  next: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    "& svg": {
      width: 5,
      height: 8,
      marginLeft: 5,
    },
  },
  disabled: {
    pointerEvents: "none" as "none",
    opacity: 0.5,
  },
});

const initialValues: any = [];

const PingTestAll = (props: IPingTestAllProps) => {
  const { mainColor, testResult, theme, classes, colorThresholds, pageSize = 10, knowledgeUrl, estimateTime, testName, config } = props;
  const pingTest: any = testResult && testResult.pingTest ? testResult.pingTest.all : initialValues;
  console.log(config);
  const currentThresholds = colorThresholds ? colorThresholds.Ping : null;

  const error = (pingTest as any)?.error;
  const [currentSlide, updateSlide] = useState(0);

  const defaultProps = {
    mainColor,
    color: theme.customColors.successColor,
    formatting: formatNumber,
  };
  const colorTypes = {
    errorColor: theme.customColors.errorColor,
    warningColor: theme.customColors.warningColor,
    successColor: theme.customColors.successColor,
  };
  const isFirstSlide = currentSlide === 0;
  const isLastSlide = currentSlide + pageSize >= (pingTest as any)?.length;
  const isNeedScroll = !isFirstSlide || !isLastSlide;
  const { t } = useTranslation(["common", "tests"]);
  console.log(pingTest);
  return (
    <TestHeader
      icon={<PingIco />}
      title={t("tests:pingtestall.title")}
      tooltip={t("tests:pingtestall.tooltip")}
      error={error}
      knowledgeUrl={knowledgeUrl}
      estimateTime={estimateTime}
      testName={testName}
    >
      <div className="tests-card-content tests-card-content--min tst-ping-all">
        {(pingTest as any)?.length ? (
          <>
            {isNeedScroll && (
              <div className={classes.slider}>
                <span
                  className={`${classes.prev} ${isFirstSlide ? classes.disabled : ""} tst-prev`}
                  onClick={() => updateSlide(currentSlide - pageSize)}
                >
                  <ChevronIco />
                  {t("tests:pingtestall.previous")}
                </span>
                <span
                  className={`${classes.next} ${isLastSlide ? classes.disabled : ""} tst-next`}
                  onClick={() => updateSlide(currentSlide + pageSize)}
                >
                  {t("tests:pingtestall.next")}
                  <ChevronIco />
                </span>
              </div>
            )}
            <table>
              <tbody>
                {pingTest.slice(currentSlide, currentSlide + pageSize).map((test: IPing, i: number) => {
                  let region: any = test.region;
                  if (region && region.match(/\(.+\)/)) {
                    region = region.replace("(", "").replace(")", "");
                  }
                  return (
                    <tr key={i} className={formatId(region)} id={`PBT-r-ping ${i}`}>
                      <td className="tst-name">{region || "Unknown"}</td>
                      <td id={`PBT-c-ping ${i}`}>
                        <RenderValues
                          {...defaultProps}
                          value={test.ping === -1 ? "error" : test.ping}
                          formatting={(value: number) => `${formatNumber(value)} <span>ms</span>`}
                          color={
                            currentThresholds && currentThresholds.ping
                              ? getColorValue(
                                  {
                                    ...colorTypes,
                                    value: test.ping,
                                    warningValue: currentThresholds.ping.warning,
                                    errorValue: currentThresholds.ping.error,
                                  },
                                  currentThresholds.ping.isLess
                                )
                              : defaultProps.color
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        ) : null}
      </div>
    </TestHeader>
  );
};

export default withTheme()(withStyles(styles)(PingTestAll));
