import React from "react";
import { useTranslation } from "react-i18next";
import withTheme from "@material-ui/core/styles/withTheme";
import TestHeader from "../TestHeader";
import { DnsLookupIco } from "../../icons";
import { IColorThresholds, TestConfiguration } from "twillio-tests/core/testConfiguration";
import { formatNumber, getColorValue } from "../../../helpers/utils";
import { IHardwareAvailability, ITestsResult } from "twillio-tests/core/TestResults";
import { Typography, withStyles } from "@material-ui/core";
import ResultRow from "./UI/ResultRow";

interface IHardwareAvailabilityTest {
  mainColor?: string;
  testResult?: ITestsResult;
  theme?: any;
  colorThresholds?: IColorThresholds;
  knowledgeUrl?: string;
  estimateTime?: number;
  testName?: string;
  currentTheme?: string;
  classes?: any;
  config?: TestConfiguration;
  logs?: any;
}

const styles = (theme: any): any => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  topResults: {
    display: "flex",
    gap: "9px",
    padding: "16px",
  },
  machineInfo: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    flex: 1,
  },
  resultName: {
    color: theme.newPalette.text.primary,
    textAlign: "center",
  },
  machineValues: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  machineValueUnit: {
    display: "flex",
    alignItems: "baseline",
  },
  display: {
    fontSize: "38px",
    fontWeight: 700,
    lineHeight: 1.5,
    letterSpacing: "-0.5px",
    color: theme.newPalette.text.primary,
  },
  chargingColor: {
    color: theme.newPalette.text.primary,
  },
  notChargingColor: {
    color: theme.newPalette.error.main,
  },
  caption: {
    color: theme.newPalette.text.secondary,
  },
  bottomResults: {
    padding: "0 16px 16px 16px",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    "& > :not(:first-child)": {
      borderTopWidth: 1,
      borderBottomWidth: 0,
      borderTop: `1px solid ${theme.newPalette.other.divider}`,
    },
  },
});

const initialValues = {};

const HardwareAvailabilityTest = (props: IHardwareAvailabilityTest) => {
  const { testResult, knowledgeUrl, estimateTime, testName, classes, colorThresholds, theme, mainColor } = props;

  const hardwareAvailability: IHardwareAvailability = testResult && testResult.hardwareAvailability ? testResult.hardwareAvailability : initialValues;

  const { meta, CPU, memory, battery, storage, general } = hardwareAvailability;

  const batteryValue = Number(battery?.percentage.replace("%", ""));
  const cpuValue = Number(CPU?.percentageUsedCPU.replace("%", ""));
  const usedMemory = memory?.usedMemory.split(" ");
  const memoryValue = usedMemory ? Number(usedMemory[0]) : NaN;
  const usedMemoryPercentage = Number(memory?.percentageUsedMemory.replace("%", " "));

  const currentThresholds = colorThresholds?.HardwareAvailability || null;

  const defaultProps = {
    mainColor,
    color: theme.newPalette.text.primary,
    formatting: formatNumber,
  };

  const colorTypes = {
    errorColor: theme.newPalette.error.main,
    warningColor: theme.newPalette.warning.main,
    successColor: theme.newPalette.text.primary,
  };

  const osInfo = general?.platform ? general?.platform + " " + general?.version : general?.operatingSystem;

  function getModelName(input: string | undefined): string {
    if (input === undefined) {
      return "";
    }
    if (input.startsWith("Apple")) {
      return input;
    }

    const regex = /\b(AMD|Intel)\b/;
    const match = input.match(regex);

    if (match) {
      return match[1];
    }

    return "";
  }

  const { t } = useTranslation(["common", "tests"]);

  const isMac = getModelName(CPU?.modelName)
    .toLowerCase()
    .includes("apple");

  return (
    <TestHeader
      icon={<DnsLookupIco />}
      title={t("tests:hardwareAvailability.title")}
      tooltip={t("tests:hardwareAvailability.tooltip")}
      error={general?.operatingSystem === undefined && Object.keys(hardwareAvailability).length !== 0 ? "Extension is not installed" : undefined}
      knowledgeUrl={knowledgeUrl}
      estimateTime={estimateTime}
      errorsCount={meta?.errorsCount}
      testName={testName}
    >
      <div className={classes.wrapper}>
        <div className={classes.topResults}>
          <div className={classes.machineInfo} id="hwa-r-cpu">
            <div className={classes.resultName}>
              <Typography className={classes.resultName} variant="body1">
                CPU
              </Typography>
            </div>
            <div className={classes.machineValues}>
              <div className={classes.machineValueUnit}>
                <Typography
                  className={classes.display}
                  style={{
                    color:
                      currentThresholds && currentThresholds.cpu
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: cpuValue,
                              warningValue: currentThresholds.cpu.warning,
                              errorValue: currentThresholds.cpu.error,
                            },
                            currentThresholds.cpu.isLess
                          )
                        : defaultProps.color,
                  }}
                  id="hwa-c-cpu"
                >
                  {isNaN(cpuValue) ? "N/A" : cpuValue}
                </Typography>
                <Typography variant="caption" className={classes.caption}>
                  {!isNaN(cpuValue) && "%"}
                </Typography>
              </div>
              <Typography variant="body2" className={classes.caption}>
                of {CPU?.numberOfProcessors} cores
              </Typography>
            </div>
          </div>
          {!isMac && (
            <div className={classes.machineInfo} id="hwa-r-memory">
              <div className={classes.resultName}>
                <Typography className={classes.resultName} variant="body1">
                  Memory
                </Typography>
              </div>
              <div className={classes.machineValues}>
                <div className={classes.machineValueUnit}>
                  <Typography
                    className={classes.display}
                    style={{
                      color:
                        currentThresholds && currentThresholds.memory
                          ? getColorValue(
                              {
                                ...colorTypes,
                                value: usedMemoryPercentage,
                                warningValue: currentThresholds.memory.warning,
                                errorValue: currentThresholds.memory.error,
                              },
                              currentThresholds.memory.isLess
                            )
                          : defaultProps.color,
                    }}
                    id="hwa-c-memory"
                  >
                    {isNaN(usedMemoryPercentage) ? "N/A" : usedMemoryPercentage}
                  </Typography>
                  <Typography variant="caption" className={classes.caption}>
                    %
                  </Typography>
                </div>
                <Typography variant="body2" className={classes.caption}>
                  of {memory?.totalMemory}
                </Typography>
              </div>
            </div>
          )}
          <div className={classes.machineInfo} id="hwa-r-battery">
            <div className={classes.resultName}>
              <Typography className={classes.resultName} variant="body1">
                Battery
              </Typography>
            </div>
            <div className={classes.machineValues}>
              <div className={classes.machineValueUnit}>
                <Typography
                  className={`${classes.display}`}
                  style={{
                    color:
                      currentThresholds && currentThresholds.battery
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: batteryValue,
                              warningValue: currentThresholds.battery.warning,
                              errorValue: currentThresholds.battery.error,
                            },
                            currentThresholds.battery.isLess
                          )
                        : defaultProps.color,
                  }}
                  id="hwa-c-battery"
                >
                  {isNaN(batteryValue) ? "N/A" : batteryValue}
                </Typography>
                <Typography variant="caption" className={classes.caption}>
                  {!isNaN(batteryValue) && "%"}
                </Typography>
              </div>
              <Typography variant="body2" className={classes.caption}>
                {battery?.charging ? "Charging" : "Not charging"}
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.bottomResults}>
          <ResultRow label={"Model"} value={getModelName(CPU?.modelName)} variant="one-line" size="small" id="hwa-r-model" valueId="hwa-c-model" />
          <ResultRow
            label={"Storage"}
            value={storage?.totalStorageSpace}
            variant="one-line"
            size="small"
            id="hwa-r-storage"
            valueId="hwa-c-storage"
          />
          <ResultRow label={"OS"} value={osInfo} variant="one-line" size="small" classes={classes.os} id="hwa-r-os" valueId="hwa-c-os" />
        </div>
      </div>
    </TestHeader>
  );
};

export default withTheme()(withStyles(styles)(HardwareAvailabilityTest));
