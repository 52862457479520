import { createStore, applyMiddleware, compose } from "redux";
import { routerMiddleware } from "react-router-redux";
import thunk from "redux-thunk";
import * as _history from "history";
import rootReducer from "../reducers";

const currentWindow: any = window;

const composeEnhancers =
  typeof currentWindow === "object" && currentWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? currentWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

export const history = _history.createBrowserHistory();
const reduxRouterMiddleware = routerMiddleware(history);
const enhancer = composeEnhancers(applyMiddleware(thunk, reduxRouterMiddleware));
export const store = createStore(rootReducer, enhancer);
