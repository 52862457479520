import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Scrollbars from "react-custom-scrollbars";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormHelperText from "@material-ui/core/FormHelperText";
import { TestConfiguration } from "twillio-tests/core/testConfiguration";
import { useTranslation } from "react-i18next";

interface IPasswordModalProps {
  classes: any;
  open: boolean;
  submitting: boolean;
  isValid: boolean;
  theme?: any;
  config?: TestConfiguration;
  onSubmit(value: string): void;
  handleClose(): void;
}

const styles = (theme: any) => ({
  paper: {
    position: "absolute" as "absolute",
    maxWidth: 400,
    width: "90%",
    backgroundColor: "#fff",
    padding: "20px 16px 10px 10px",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  newPaper: {
    position: "absolute" as "absolute",
    maxWidth: 440,
    width: "100%",
    background: theme.newPalette.cardBackground,
    padding: "16px 24px",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "4px",
    boxShadow: "0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
  },
  content: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "stretch" as "stretch",
  },
  title: {
    display: "block",
    textAlign: "center" as "center",
    fontSize: 20,
    fontWeight: 600,
    padding: "0 0 10px",
    borderBottom: "1px solid #c9cbd1",
    marginBottom: 16,
    color: theme.palette.primary.main,
  },
  title_mend: {
    fontWeight: 500,
  },
  title_amazon: {
    fontWeight: 400,
  },
  field: {
    marginLeft: 20,
    marginRight: 20,
    marginTop: 20,
    marginBottom: 3,
    width: "calc(100%-40px)",
  },
  newField: {
    "& label": {
      // fontFamily: "Roboto, sans-serif !important",
    },
  },
  error: {
    marginLeft: 20,
    marginBottom: 20,
    color: "red",
  },
  newError: {
    // fontFamily: "Roboto, sans-serif !important",
    marginLeft: "12px",
    color: theme.newPalette.error.main,
  },
  button: {
    minWidth: 200,
    alignSelf: "center" as "center",
    color: theme.newPalette.primary.contrast,
    backgroundColor: theme.newPalette.primary.testrtc.main,
  },
  close: {
    position: "absolute" as "absolute",
    bottom: "100%",
    right: 0,
    color: "#fff",
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 15,
    cursor: "pointer",
  },
  titleColor: {
    color: theme.newPalette.text.primary,
    marginBottom: "24px",
  },
  controlBtns: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "16px",
    gap: "8px",
  },
  newBtn: {
    padding: "6px 16px",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "0.4px",
    color: "white",
    background: "#3577C1",
    height: "36px",
  },
  cancelBtn: {
    padding: "6px 8px",
    background: "transparent",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "0.4px",
    color: theme.newPalette.text.secondary,
    height: "36px",
  },
  floatingLabelFocusStyle: {
    color: `${theme.newPalette.text.primary} !important`,
  },
  textField: {
    color: theme.newPalette.text.primary,
  },
});

const PasswordModal = (props: IPasswordModalProps) => {
  const [value, setValue] = useState("");

  const { classes, onSubmit, isValid, config } = props;

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onSubmit(value);
  };

  const handleChange = (event: any) => {
    setValue(event.currentTarget.value);
  };

  const { t } = useTranslation(["tests"]);

  return (
    <Modal open={props.open} onClose={props.handleClose}>
      {config?.useNewWidgetDesign ? (
        <div className={classes.newPaper}>
          <Typography variant="h3" className={classes.titleColor}>
            {t("tests:passwordModal.title")}
          </Typography>
          <form className={classes.content} onSubmit={handleSubmit}>
            <TextField
              autoComplete="on"
              type="password"
              autoFocus
              id="password"
              label="Password"
              variant="outlined"
              value={value}
              className={classes.newField}
              onChange={handleChange}
              error={!isValid}
              inputProps={{
                style: {
                  padding: "12px 16px",
                  height: "32px",
                },
                className: classes.textField,
              }}
              InputLabelProps={{
                classes: {
                  root: classes.textField,
                  focused: classes.floatingLabelFocusStyle,
                },
              }}
            />
            <FormHelperText className={classes.newError}>{isValid ? "" : "Invalid password"}</FormHelperText>
            <div className={classes.controlBtns}>
              <Button id="submit" type="button" variant="text" onClick={props.handleClose} className={classes.cancelBtn}>
                {t("tests:passwordModal.cancel")}
              </Button>
              <Button disabled={props.submitting} id="submit" type="submit" className={classes.newBtn}>
                {t("tests:passwordModal.run")}
              </Button>
            </div>
          </form>
        </div>
      ) : (
        <div className={classes.paper}>
          <span className={classes.close} onClick={props.handleClose}>
            Close
          </span>
          <Typography variant="h6" className={`${classes.title} ${classes[`title_${props.theme}`]}`}>
            Password
          </Typography>
          <form className={classes.content} onSubmit={handleSubmit}>
            <TextField
              autoComplete="on"
              type="password"
              autoFocus
              id="password"
              label="Password"
              variant="outlined"
              value={value}
              className={classes.field}
              onChange={handleChange}
            />
            <FormHelperText className={classes.error}>{isValid ? "" : "Invalid password"}</FormHelperText>
            <Button disabled={props.submitting} id="submit" type="submit" className={classes.button}>
              Start
            </Button>
          </form>
        </div>
      )}
    </Modal>
  );
};

export default withStyles(styles)(PasswordModal);
