import React, { Component } from "react";
import { connect } from "react-redux";
import withTheme from "@material-ui/core/styles/withTheme";
import withStyles from "@material-ui/core/styles/withStyles";
import { AdditionalTestNames } from "twillio-tests/config";
import { IColorThresholds, TestConfiguration } from "twillio-tests/core/testConfiguration";

import "./TestsContainer.scss";
import VideoPlaybackTest from "./Tests/VideoPlaybackTest";
import { ITestsResult } from "twillio-tests/core/TestResults";
import BrowserCanRunTest from "./Tests/BrowserCanRunTest";
import AdditionalTestErrorBox from "./Tests/AdditionalTestErrorBox";
import GetUserMediaTest from "./Tests/GetUserMediaTest";

function mapStateToProps(state: any) {
  return {
    testsList: state.tests.testsList,
    testResult: state.tests.testResult,
    config: state.tests.config,
    colorThresholds: state.document.colorThresholds,
    currentTheme: state.document.theme,
    customError: state.document.customError,
  };
}

const styles = (theme: any) => ({
  container: {
    padding: "0",
    display: "flex",
    flexWrap: "wrap" as "wrap",
    margin: "0 0px",
  },
  cardItem: {
    width: "100%",
    padding: "0",
  },
  card: {
    background: "#fff",
    border: "none",
    padding: 0,
    color: theme.palette.secondary.main,
    minHeight: 0,
    display: "flex",
    flexDirection: "column" as "column",
    overflowX: "hidden" as "hidden",
  },
  card_avatour: {
    background: theme.palette.secondary.main,
  },
});

interface ITestsContainerProps {
  testsList?: string[];
  classes?: any;
  theme: any;
  config?: TestConfiguration;
  testResult?: ITestsResult;
  colorThresholds?: IColorThresholds;
  currentTheme?: string;
  customError?: any;
}

class AdditionalTestsContainer extends Component<ITestsContainerProps> {
  renderTests() {
    const wrapElements = (elements: any, test?: string, someKey?: string) => {
      return (
        <div
          className={`${classes.cardItem} ${test} ${classes[`cardItem_${test}`]}`}
          key={`${test} ${someKey}`}
        >
          <div className={`${classes.card} ${classes[`card_${currentTheme}`]}`}>{elements}</div>
        </div>
      );
    };
    const { theme, classes, config, testsList = [], currentTheme } = this.props;
    const tests = testsList.map((test: string) => {
      const testName: AdditionalTestNames =
        AdditionalTestNames[test as keyof typeof AdditionalTestNames];
      let element;

      const defaultProps = {
        pageSize: config?.pageSize || 3,
        mainColor: theme.palette.primary.main,
        testResult: this.props.testResult,
        colorThresholds: this.props.colorThresholds,
        knowledgeUrl: config?.knowledgeUrl?.[test],
      };

      switch (testName) {
        case AdditionalTestNames.VideoPlayback:
          element = <VideoPlaybackTest {...defaultProps} />;
          break;
        case AdditionalTestNames.GetUserMedia:
          element = <GetUserMediaTest {...defaultProps} config={this.props.config} />;
          break;
        case AdditionalTestNames.DNSSetup:
          //Removing the error message from UI for DNSSetup testhttps://redmine.testrtc.com/issues/9778#note-5
          // element = (
          //   <AdditionalTestErrorBox {...defaultProps} data={defaultProps.testResult?.dnsSetup} />
          // );
          break;
        default:
          element = null;
          break;
      }
      if (element === null) {
        return element;
      }
      if (this.props.customError) {
        element = <AdditionalTestErrorBox {...defaultProps} data={this.props.customError} />;
      }
      return wrapElements(element, test);
    });

    const browserCanRunTests = (): JSX.Element | null => {
      return wrapElements(<BrowserCanRunTest testResult={this.props.testResult} />);
    };

    return [browserCanRunTests()].concat(tests);
  }

  render() {
    const displayTests = this.props.testResult;

    return (
      <div
        style={{
          display: displayTests ? "block" : "none",
          // https://redmine.testrtc.com/issues/9435 (Unnecessary line is showing below widgets)
          // border: "1px solid #c9cbd1",
          marginBottom: 15,
          pageBreakBefore: displayTests ? "always" : "unset",
        }}
        className={this.props.classes.container}
      >
        {this.renderTests()}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withTheme()(AdditionalTestsContainer)));
