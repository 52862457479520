import React from "react";

export const LogIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 12C14.24 12 12 14.24 12 17C12 19.76 14.24 22 17 22C19.76 22 22 19.76 22 17C22 14.24 19.76 12 17 12ZM18.65 19.35L16.5 17.2V14H17.5V16.79L19.35 18.64L18.65 19.35ZM18 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H6C4.9 3 4 3.9 4 5V20C4 21.1 4.9 22 6 22H12.11C11.52 21.43 11.04 20.75 10.69 20H6V5H8V8H16V5H18V10.08C18.71 10.18 19.38 10.39 20 10.68V5C20 3.9 19.1 3 18 3ZM12 5C11.45 5 11 4.55 11 4C11 3.45 11.45 3 12 3C12.55 3 13 3.45 13 4C13 4.55 12.55 5 12 5Z"
        fill="#243544"
        fillOpacity="0.54"
      />
    </svg>
  );
};

export const IdIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.8333 1.66667H4.16667C3.24167 1.66667 2.5 2.41667 2.5 3.33333V15C2.5 15.9167 3.24167 16.6667 4.16667 16.6667H7.5L10 19.1667L12.5 16.6667H15.8333C16.75 16.6667 17.5 15.9167 17.5 15V3.33333C17.5 2.41667 16.75 1.66667 15.8333 1.66667ZM15.8333 15H11.8083L10 16.8083L8.19167 15H4.16667V3.33333H15.8333V15ZM10 9.16667C11.375 9.16667 12.5 8.04167 12.5 6.66667C12.5 5.29167 11.375 4.16667 10 4.16667C8.625 4.16667 7.5 5.29167 7.5 6.66667C7.5 8.04167 8.625 9.16667 10 9.16667ZM10 5.83333C10.4583 5.83333 10.8333 6.20833 10.8333 6.66667C10.8333 7.125 10.4583 7.5 10 7.5C9.54167 7.5 9.16667 7.125 9.16667 6.66667C9.16667 6.20833 9.54167 5.83333 10 5.83333ZM15 12.9833C15 10.9 11.6917 10 10 10C8.30833 10 5 10.9 5 12.9833V14.1667H15V12.9833ZM7.06667 12.5C7.68333 12.075 8.925 11.6667 10 11.6667C11.075 11.6667 12.3167 12.075 12.9333 12.5H7.06667Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const RerunIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.7085 5.29167C13.5001 4.08333 11.8418 3.33333 10.0001 3.33333C6.3168 3.33333 3.3418 6.31667 3.3418 10C3.3418 13.6833 6.3168 16.6667 10.0001 16.6667C13.1085 16.6667 15.7001 14.5417 16.4418 11.6667H14.7085C14.0251 13.6083 12.1751 15 10.0001 15C7.2418 15 5.00013 12.7583 5.00013 10C5.00013 7.24167 7.2418 5 10.0001 5C11.3835 5 12.6168 5.575 13.5168 6.48333L10.8335 9.16667H16.6668V3.33333L14.7085 5.29167Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const DownloadIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 9H15V3H9V9H5L12 16L19 9ZM11 11V5H13V11H14.17L12 13.17L9.83 11H11ZM5 18H19V20H5V18Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const LanguageIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM18.92 8H15.97C15.65 6.75 15.19 5.55 14.59 4.44C16.43 5.07 17.96 6.35 18.92 8ZM12 4.04C12.83 5.24 13.48 6.57 13.91 8H10.09C10.52 6.57 11.17 5.24 12 4.04ZM4.26 14C4.1 13.36 4 12.69 4 12C4 11.31 4.1 10.64 4.26 10H7.64C7.56 10.66 7.5 11.32 7.5 12C7.5 12.68 7.56 13.34 7.64 14H4.26ZM5.08 16H8.03C8.35 17.25 8.81 18.45 9.41 19.56C7.57 18.93 6.04 17.66 5.08 16ZM8.03 8H5.08C6.04 6.34 7.57 5.07 9.41 4.44C8.81 5.55 8.35 6.75 8.03 8ZM12 19.96C11.17 18.76 10.52 17.43 10.09 16H13.91C13.48 17.43 12.83 18.76 12 19.96ZM14.34 14H9.66C9.57 13.34 9.5 12.68 9.5 12C9.5 11.32 9.57 10.65 9.66 10H14.34C14.43 10.65 14.5 11.32 14.5 12C14.5 12.68 14.43 13.34 14.34 14ZM14.59 19.56C15.19 18.45 15.65 17.25 15.97 16H18.92C17.96 17.65 16.43 18.93 14.59 19.56ZM16.36 14C16.44 13.34 16.5 12.68 16.5 12C16.5 11.32 16.44 10.66 16.36 10H19.74C19.9 10.64 20 11.31 20 12C20 12.69 19.9 13.36 19.74 14H16.36Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const CloseIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M15.8332 5.34167L14.6582 4.16667L9.99984 8.825L5.3415 4.16667L4.1665 5.34167L8.82484 10L4.1665 14.6583L5.3415 15.8333L9.99984 11.175L14.6582 15.8333L15.8332 14.6583L11.1748 10L15.8332 5.34167Z"
        fill="#9747FF"
      />
      <path
        d="M15.8332 5.34167L14.6582 4.16667L9.99984 8.825L5.3415 4.16667L4.1665 5.34167L8.82484 10L4.1665 14.6583L5.3415 15.8333L9.99984 11.175L14.6582 15.8333L15.8332 14.6583L11.1748 10L15.8332 5.34167Z"
        fill="#243544"
        fillOpacity="0.6"
      />
    </svg>
  );
};

export const GrayCloseIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const InfoIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path
        d="M10.0835 6.41667H11.9168V8.25H10.0835V6.41667ZM10.0835 10.0833H11.9168V15.5833H10.0835V10.0833ZM11.0002 1.83333C5.94016 1.83333 1.8335 5.94 1.8335 11C1.8335 16.06 5.94016 20.1667 11.0002 20.1667C16.0602 20.1667 20.1668 16.06 20.1668 11C20.1668 5.94 16.0602 1.83333 11.0002 1.83333ZM11.0002 18.3333C6.95766 18.3333 3.66683 15.0425 3.66683 11C3.66683 6.9575 6.95766 3.66667 11.0002 3.66667C15.0427 3.66667 18.3335 6.9575 18.3335 11C18.3335 15.0425 15.0427 18.3333 11.0002 18.3333Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const OpenInNewIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 19H5V5H12V3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V12H19V19ZM14 3V5H17.59L7.76 14.83L9.17 16.24L19 6.41V10H21V3H14Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const LogErrorIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.59 8L12 10.59L9.41 8L8 9.41L10.59 12L8 14.59L9.41 16L12 13.41L14.59 16L16 14.59L13.41 12L16 9.41L14.59 8ZM12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
        fill="#D32F2F"
      />
    </svg>
  );
};

export const LogWarningIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 11V13H17V11H7ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
        fill="#FC9A25"
      />
    </svg>
  );
};

export const ChevronDownIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.5575 6.44238L9 9.87738L12.4425 6.44238L13.5 7.49988L9 11.9999L4.5 7.49988L5.5575 6.44238Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const EmailIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6665 3.33333H3.33317C2.4165 3.33333 1.6665 4.08333 1.6665 5V15C1.6665 15.9167 2.4165 16.6667 3.33317 16.6667H10.8332V15H3.33317V6.66667L9.99984 10.8333L16.6665 6.66667V10.8333H18.3332V5C18.3332 4.08333 17.5832 3.33333 16.6665 3.33333ZM9.99984 9.16667L3.33317 5H16.6665L9.99984 9.16667ZM15.8332 12.5L19.1665 15.8333L15.8332 19.1667V16.6667H12.4998V15H15.8332V12.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const LightIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 9C13.65 9 15 10.35 15 12C15 13.65 13.65 15 12 15C10.35 15 9 13.65 9 12C9 10.35 10.35 9 12 9ZM12 7C9.24 7 7 9.24 7 12C7 14.76 9.24 17 12 17C14.76 17 17 14.76 17 12C17 9.24 14.76 7 12 7ZM2 13H4C4.55 13 5 12.55 5 12C5 11.45 4.55 11 4 11H2C1.45 11 1 11.45 1 12C1 12.55 1.45 13 2 13ZM20 13H22C22.55 13 23 12.55 23 12C23 11.45 22.55 11 22 11H20C19.45 11 19 11.45 19 12C19 12.55 19.45 13 20 13ZM11 2V4C11 4.55 11.45 5 12 5C12.55 5 13 4.55 13 4V2C13 1.45 12.55 1 12 1C11.45 1 11 1.45 11 2ZM11 20V22C11 22.55 11.45 23 12 23C12.55 23 13 22.55 13 22V20C13 19.45 12.55 19 12 19C11.45 19 11 19.45 11 20ZM5.99 4.58C5.6 4.19 4.96 4.19 4.58 4.58C4.19 4.97 4.19 5.61 4.58 5.99L5.64 7.05C6.03 7.44 6.67 7.44 7.05 7.05C7.43 6.66 7.44 6.02 7.05 5.64L5.99 4.58ZM18.36 16.95C17.97 16.56 17.33 16.56 16.95 16.95C16.56 17.34 16.56 17.98 16.95 18.36L18.01 19.42C18.4 19.81 19.04 19.81 19.42 19.42C19.81 19.03 19.81 18.39 19.42 18.01L18.36 16.95ZM19.42 5.99C19.81 5.6 19.81 4.96 19.42 4.58C19.03 4.19 18.39 4.19 18.01 4.58L16.95 5.64C16.56 6.03 16.56 6.67 16.95 7.05C17.34 7.43 17.98 7.44 18.36 7.05L19.42 5.99ZM7.05 18.36C7.44 17.97 7.44 17.33 7.05 16.95C6.66 16.56 6.02 16.56 5.64 16.95L4.58 18.01C4.19 18.4 4.19 19.04 4.58 19.42C4.97 19.8 5.61 19.81 5.99 19.42L7.05 18.36Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const DarkIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.37 5.51C9.19 6.15 9.1 6.82 9.1 7.5C9.1 11.58 12.42 14.9 16.5 14.9C17.18 14.9 17.85 14.81 18.49 14.63C17.45 17.19 14.93 19 12 19C8.14 19 5 15.86 5 12C5 9.07 6.81 6.55 9.37 5.51ZM12 3C7.03 3 3 7.03 3 12C3 16.97 7.03 21 12 21C16.97 21 21 16.97 21 12C21 11.54 20.96 11.08 20.9 10.64C19.92 12.01 18.32 12.9 16.5 12.9C13.52 12.9 11.1 10.48 11.1 7.5C11.1 5.69 11.99 4.08 13.36 3.1C12.92 3.04 12.46 3 12 3Z"
        fill="currentColor"
      />
    </svg>
  );
};
