import React from "react";
import { connect } from "react-redux";
import Scrollbars from "react-custom-scrollbars";

import isArray from "lodash/isArray";
import startCase from "lodash/startCase";
import isNaN from "lodash/isNaN";
import isObject from "lodash/isObject";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";

import { ILog, LogColors } from "twillio-tests/core/TestResults";

interface ILogModalProps {
  classes?: any;
  scrollable?: boolean;
  uuid?: any;
}

interface IDispatch {
  theme?: string;
  logs?: ILog[];
}

const containsHtml = (messageText: any) =>
  /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/.test(messageText);

const mapStateToProps = (state: any) => {
  return {
    theme: state.document.theme,
    logs: state.tests.logs,
    uuid: state.document.uuid,
  };
};

const styles = (theme: any) => ({
  paper: {
    position: "absolute" as "absolute",
    backgroundColor: "#fff",
    padding: "20px 16px 10px",
    outline: "none",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  title: {
    display: "block",
    textAlign: "center" as "center",
    fontSize: 20,
    fontWeight: 600,
    padding: "0 0 10px",
    borderBottom: "1px solid #c9cbd1",
    marginBottom: 16,
    color: theme.palette.primary.main,
  },
  title_mend: {
    fontWeight: 500,
  },
  title_amazon: {
    fontWeight: 400,
  },
  plainMessage: {
    color: theme.palette.secondary.main,
    fontSize: 14,
    fontWeight: 400,
    paddingBottom: 5,
    paddingRight: 17,
    paddingLeft: 3,
    "&.red": {
      color: theme.customColors.errorColor,
    },
    "&.green": {
      color: theme.customColors.successColor,
    },
  },
  plainMessage_avatour: {
    "&.green": {
      color: theme.palette.secondary.main,
    },
  },
  content: {
    height: 480,
    overflow: "auto",
    padding: "5px 70px 5px 5px",
    "& > div:last-child": {
      background: "#c9cbd1!important",
      width: "5px!important",
      "& div": {
        background: `${theme.palette.primary.main}!important`,
        width: "3px!important",
        left: 1,
        top: 1,
      },
    },
  },
  close: {
    position: "absolute" as "absolute",
    bottom: "100%",
    right: 0,
    color: "#fff",
    fontSize: 20,
    fontWeight: 600,
    marginBottom: 15,
    cursor: "pointer",
  },
  table: {
    width: "100%",
    "& tr": {
      "& td": {
        "& p": {
          margin: 0,
          color: theme.palette.secondary.main,
          fontSize: 14,
          fontWeight: 400,
          paddingBottom: 5,
          paddingRight: 5,
          "&.red": {
            color: theme.customColors.errorColor,
          },
          "&.green": {
            color: theme.customColors.successColor,
          },
        },
        "&:first-child": {
          width: 75,
          whiteSpace: "nowrap" as "nowrap",
        },
        "&:nth-child(2)": {
          width: 1,
        },
        "&:nth-child(3)": {
          width: 1,
          whiteSpace: "nowrap" as "nowrap",
        },
        "&:last-child": {
          "& p": {
            fontWeight: 600,
          },
        },
      },
    },
  },
});

const makeRow = (keyText: string, value: any, time: any) => {
  const hasHTML = containsHtml(value.toString());

  const valueP = hasHTML ? (
    <p dangerouslySetInnerHTML={{ __html: value.toString() }} />
  ) : (
    <p>{value.toString()}</p>
  );

  return (
    <tr key={keyText + value.toString() + Math.random() * 100}>
      <td>
        <p>{`${time ? `[${time}]` : ""}`}</p>
      </td>
      <td>
        <p>-</p>
      </td>
      <td>
        <p>{keyText}</p>
      </td>
      <td>{valueP}</td>
    </tr>
  );
};

// function to convert array to objects

const convertArrayToObject = (arr: Array<any>): any => {
  const obj = {};
  arr.forEach(function (a, index) {
    if (isObject(a)) {
      // check if value is object or string
      Object.keys(a).forEach((key) => {
        obj[key] = a[key];
      });
    } else {
      obj[index] = a;
    }
  });
  return obj;
};

const getRows = (object: any, time: any, color: LogColors, inner?: boolean): JSX.Element[] => {
  let result: any[] = [];
  Object.keys(object).forEach((key: string, index: number) => {
    const value = object[key];
    time = index === 0 && !inner ? time : "";

    if (isArray(value)) {
      if (isNaN(Number(key))) {
        const innerObjectHeaderRow = makeRow(`${startCase(key)}:`, "", time);
        result = result.concat(innerObjectHeaderRow);
      }

      const values = convertArrayToObject(value); // get values from array as object
      const innerRows = getRows({ ...values }, time, color, true);
      result = result.concat(innerRows);
      return;
    }

    if (isObject(value)) {
      const innerObjectHeaderRow = makeRow(`${startCase(key)}:`, "", time);
      result.push(innerObjectHeaderRow);
      const innerRows = getRows(value, time, color, true);
      result = result.concat(innerRows);
      return;
    }

    if (value !== undefined && value !== null) {
      const keyText = !inner
        ? `${startCase(key)}:`
        : inner && !isNaN(Number(key))
        ? `${startCase(key)}:`
        : `[${index + 1}] ${startCase(key)}:`;
      const row = makeRow(keyText, value, time);
      result.push(row);
    }
  });
  return result;
};

const LogsComponent = (props: ILogModalProps & IDispatch) => {
  const { classes, logs, theme } = props;

  const refs = logs?.reduce((acc: any, value: any) => {
    acc[value.message] = React.createRef();
    return acc;
  }, {});

  const plainMessage = (idx: number, message: string, time: any, color: LogColors) => {
    if (!message) {
      return null;
    }

    const messageText = typeof message === "string" ? message : (message as Error).message;

    // e.g. === Location ===
    const isTestHeader = messageText.includes("===");
    const isTestFooter = messageText.includes("End ===");

    if (containsHtml(messageText)) {
      return (
        <Typography
          key={idx}
          className={`${classes.plainMessage} ${classes[`plainMessage_${theme}`]} ${
            isTestFooter ? "black" : isTestHeader ? "green" : color
          }`}
          dangerouslySetInnerHTML={{
            __html: ` [${time}]${isTestHeader ? " " : "  -  "}${messageText}`,
          }}
        ></Typography>
      );
    }

    return (
      <div key={idx} ref={refs[messageText]}>
        <Typography
          key={idx}
          className={`${classes.plainMessage} ${classes[`plainMessage_${theme}`]} ${
            isTestFooter ? "black" : isTestHeader ? "green" : color
          }`}
        >
          {` [${time}]${isTestHeader ? " " : "  -  "}${messageText}`}
        </Typography>
      </div>
    );
  };

  const complexMessage = (idx: number, message: string, time: any, color: LogColors) => {
    const rows = getRows(message, time, color);
    return (
      <table key={idx} className={classes.table}>
        <tbody>{rows}</tbody>
      </table>
    );
  };

  return (
    <React.Fragment>
      <Typography variant="h6" className={`${classes.title} ${classes[`title_${theme}`]}`}>
        LOG
      </Typography>
      {props.uuid && <p>Test run id: {<strong>{props.uuid}</strong>}</p>}
      {props.scrollable ? (
        <Scrollbars className={classes.content} style={{ height: 480 }}>
          {logs?.map((log: ILog, i: number) => {
            if (isObject(log.message)) {
              return complexMessage(i, log.message, log.time, log.color);
            }
            return plainMessage(i, log.message, log.time, log.color);
          })}
        </Scrollbars>
      ) : (
        logs?.map((log: ILog, i: number) => {
          if (isObject(log.message)) {
            return complexMessage(i, log.message, log.time, log.color);
          }
          return plainMessage(i, log.message, log.time, log.color);
        })
      )}
    </React.Fragment>
  );
};

export default connect(mapStateToProps)(withStyles(styles)(LogsComponent));
