import { Typography, withStyles, withTheme } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { formatNumber } from "../../../../helpers/utils";
import { useTranslation } from "react-i18next";
import { colors } from "../../../App/CustomTheme";
import CustomTooltip from "../NewTests/UI/CustomTooltip";

const styles = (theme: any): any => ({
  withTitleContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    paddingBottom: "8px",
    "&:not(:first-child)": {
      paddingTop: "8px",
    },
  },
  titleColor: {
    color: theme.newPalette.text.primary,
    lineHeight: "14.4px",
    textTransform: "capitalize",
  },
  withTitleResultRow: {
    display: "flex",
    justifyContent: "space-between",
    gap: "15px",
    alignItems: "center",
  },
  withTitleSingleRow: {
    display: "flex",
    alignItems: "baseline",
    gap: "4px",
    textTransform: "capitalize",
  },
  resultLineContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "8px",
    "&:not(:first-child)": {
      paddingTop: "8px",
    },
  },
  callQualityLine: {
    "&:not(:last-child)": {
      paddingBottom: "8px",
    },
  },
  oneLineContainer: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    paddingBottom: "8px",
    "&:not(:first-child)": {
      paddingTop: "8px",
    },
    "&:not(:last-child)": {
      paddingBottom: "8px",
    },
  },
  resultLabel: {
    color: theme.newPalette.text.primary,
    fontSize: "12px",
    lineHeight: "25px",
    letterSpacing: "0.4px",
    textTransform: "capitalize",
  },
  oneLineLabel: {
    color: theme.newPalette.text.primary,
    fontSize: "12px",
    lineHeight: "14px",
    letterSpacing: "0.15px",
    textTransform: "capitalize",
    width: "82px",
  },
  unitValue: {
    textTransform: "lowercase !important",
  },
  unitColor: {
    color: theme.newPalette.text.secondary,
  },
  overall: {
    display: "flex",
    flexDirection: "column",
  },
  overallText: {
    fontSize: "12px",
    lineHeight: "20px",
    letterSpacing: "0.4px",
    textAlign: "right",
  },
  niceValue: {
    fontSize: "12px",
    lineHeight: "14.4px",
    letterSpacing: "0.4px",
    color: theme.newPalette.text.primary,
    textTransform: "capitalize",
  },
  niceContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "4px 0",
  },
  textColor: {
    color: theme.newPalette.text.primary,
  },
});

function mapStateToProps(state: any) {
  return {
    testResult: state.tests.testResult,
    currentTheme: state.document.theme,
    config: state.tests.config,
  };
}

type Variants = "with-title" | "one-line" | "nice";

interface ResultRowProps {
  classes: any;
  variant?: Variants;
  title?: string;
  color?: string;
  value?: string | number | null | undefined;
  sessionValue?: string | number | null | undefined;
  label?: string;
  unit?: string;
  callQuality?: boolean;
  size?: string;
  bitrateColor?: string;
  overall?: string;
  type?: string;
  id?: string;
  valueId?: string;
  sessionId?: string;
  tstId?: string;
  valueTstId?: string;
  formatting?: boolean;
  currentTheme?: string;
  theme?: any;
}

const ResultRow = ({
  variant,
  classes,
  title,
  color,
  value,
  sessionValue,
  label,
  unit,
  callQuality,
  size,
  bitrateColor,
  overall,
  type,
  id = "",
  valueId = "",
  tstId = "",
  valueTstId = "",
  sessionId = "",
  formatting,
  currentTheme,
  theme,
}: ResultRowProps) => {
  const { t } = useTranslation(["common", "tests"]);

  const customColor = currentTheme !== undefined ? colors[currentTheme] : null;
  const colorPalette = theme?.newPalette.isDark ? customColor?.darkMode : customColor?.lightMode;

  if (variant === "with-title") {
    return (
      <div className={classes.withTitleContainer} id={id}>
        <Typography
          variant="caption"
          className={classes.titleColor}
          style={{
            color: colorPalette ? colorPalette.textColor2 : "",
          }}
        >
          {title}
        </Typography>
        <div className={classes.withTitleResultRow}>
          <div className={classes.withTitleSingleRow} id={valueId}>
            <Typography variant="h4" style={{ color: bitrateColor }} id={tstId}>
              {formatNumber(Number(value))}
            </Typography>
            <Typography variant="caption" className={classes.unitColor} style={{ textTransform: "lowercase" }}>
              kbps
            </Typography>
          </div>
          <div className={classes.withTitleSingleRow} id={sessionId}>
            <Typography variant="h4" style={{ color: color }} id={valueTstId}>
              {formatNumber(Number(sessionValue))}
            </Typography>
            <Typography variant="caption" className={`${classes.unitColor} ${classes.unitValue}`}>
              {t("session", { count: 0 })}
            </Typography>
          </div>
        </div>
      </div>
    );
  }

  if (variant === "one-line") {
    return (
      <div className={classes.oneLineContainer} id={id}>
        <Typography
          className={classes.oneLineLabel}
          style={{
            color: colorPalette ? colorPalette.textColor2 : "",
          }}
        >
          {label}
        </Typography>
        <div className={classes.withTitleSingleRow} id={valueId}>
          <CustomTooltip title={typeof value === "string" && value.length > 31 ? value : null}>
            <Typography
              variant={size === "small" ? "h4" : "h3"}
              style={{ color: color ? color : "" }}
              className={`line-clamp-1 ${color ? "" : classes.textColor}`}
            >
              {value}
            </Typography>
          </CustomTooltip>
          <Typography variant="caption" className={`${classes.unitColor} ${classes.unitValue}`}>
            {unit}
          </Typography>
        </div>
      </div>
    );
  }

  if (variant === "nice") {
    return (
      <div className={classes.niceContainer} id={id}>
        <Typography
          className={classes.niceValue}
          style={{
            color: colorPalette ? colorPalette.textColor2 : "",
          }}
        >
          {label}
          {type && (
            <Typography className={classes.niceValue} style={{ textTransform: "lowercase" }}>
              {type === "in" ? t("in") : t("out")}
            </Typography>
          )}
        </Typography>
        <div className={classes.overall} id={valueId} style={{ alignItems: overall ? "end" : "start" }}>
          <div className={classes.withTitleSingleRow}>
            <Typography variant="h3" style={{ color: color ? color : "#243544" }}>
              {value && value === "-" ? "-" : value !== "N/A" ? formatNumber(Number(value)) : "N/A"}
            </Typography>
            <Typography variant="caption" className={`${classes.unitColor} ${classes.unitValue}`}>
              {isNaN(Number(value)) || value === undefined || (value !== "N/A" && unit)}
            </Typography>
          </div>
          {overall && <Typography className={classes.overallText}>{overall}</Typography>}
        </div>
      </div>
    );
  }

  return (
    <div className={`${classes.resultLineContainer} ${callQuality ? classes.callQualityLine : ""}`} id={id}>
      <Typography
        className={classes.resultLabel}
        style={{ lineHeight: (size = "small" ? "14.4px" : "25px"), color: colorPalette ? colorPalette.textColor2 : "" }}
      >
        {label}
      </Typography>
      <div className={classes.overall} style={{ alignItems: overall ? "end" : "start" }}>
        <div className={classes.withTitleSingleRow}>
          <Typography variant="h3" style={{ color: color ? color : "" }} className={classes.textColor} id={valueId}>
            {value && value === "-" ? "-" : value !== "N/A" ? (formatting ? value : formatNumber(Number(value))) : "N/A"}
          </Typography>
          <Typography variant="caption" className={`${classes.unitColor} ${classes.unitValue}`}>
            {isNaN(Number(value)) || value === undefined || (value !== "N/A" && unit)}
          </Typography>
        </div>
        {overall && <Typography>{overall}</Typography>}
      </div>
    </div>
  );
};

export default connect(mapStateToProps, null)(withTheme()(withStyles(styles)(ResultRow)));
