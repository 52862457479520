import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const wrapSvgPath = (path: any, viewBox: string = "0 0 24 24") => (props: any) => (
  <SvgIcon {...props} viewBox={viewBox}>
    {path}
  </SvgIcon>
);

export const ReloadIcon = wrapSvgPath(
  <path
    fillRule="evenodd"
    d={`M47.656 17.969H37.5a2.344 2.344 0 0 1 0-4.688h4.077C37.9 8.085 31.851 4.688
      25 4.688 13.782 4.688 4.688 13.782 4.688 25c0 11.218 9.094 20.313 20.312 20.313 11.218
      0 20.312-9.095 20.312-20.313A2.345 2.345 0 0 1 50 25c0 13.807-11.193 25-25 25S0 38.807 0 25
      11.193 0 25 0c8.379 0 15.78 4.131 20.312 10.461V6.25a2.345 2.345 0 0 1 4.688 0v9.375a2.343 2.343 0 0 1-2.344 2.344z`}
  />,
  "0 0 50 50"
);

export const TooltipIco = wrapSvgPath(
  <g>
    <path
      fillRule="evenodd"
      d="M25 50C11.193 50 0 38.807 0 25S11.193 0 25 0s25 11.193 25 25-11.193 25-25 25z"
    />
    <path
      fill="#FFF"
      fillRule="evenodd"
      d={`M27 17.353C27 16.054 25.881 15 24.5 15S22 16.054 22 17.353v8.824c0 1.299 1.119 2.353
      2.5 2.353s2.5-1.054 2.5-2.353v-8.824zm-2.5 12.941c-1.381 0-2.5 1.054-2.5 2.353 0 1.3 1.119 2.353
      2.5 2.353s2.5-1.053 2.5-2.353c0-1.299-1.119-2.353-2.5-2.353z`}
    />
  </g>,
  "0 0 50 50"
);
export const AvatourTooltipIco = wrapSvgPath(
  <g>
    <path
      fillRule="evenodd"
      d="M25 50C11.193 50 0 38.807 0 25S11.193 0 25 0s25 11.193 25 25-11.193 25-25 25z"
    />
    <path
      fill="#0d1922"
      fillRule="evenodd"
      d={`M27 17.353C27 16.054 25.881 15 24.5 15S22 16.054 22 17.353v8.824c0 1.299 1.119 2.353
      2.5 2.353s2.5-1.054 2.5-2.353v-8.824zm-2.5 12.941c-1.381 0-2.5 1.054-2.5 2.353 0 1.3 1.119 2.353
      2.5 2.353s2.5-1.053 2.5-2.353c0-1.299-1.119-2.353-2.5-2.353z`}
    />
  </g>,
  "0 0 50 50"
);

export const ThroughputIco = wrapSvgPath(
  <g>
    <path d="M297.26,316.22H214.74L76.56,95.34A15.9,15.9,0,0,1,90,71H422a15.9,15.9,0,0,1,13.48,24.34Z" />
    <path d="M214.74,316.22v130.2a14.56,14.56,0,0,0,22,12.53l50.17-29.68a21.12,21.12,0,0,0,10.37-18.18V316.22Z" />
  </g>,
  "0 0 512 512"
);

export const CallQualityIco = wrapSvgPath(
  <g>
    <path d="M140.48,52.37l63.92,84c9.13,11,7.31,23.74,0,32.87l-43.83,43.83c-20.09,18.26,118.71,157.06,137,138.8l43.83-43.83c9.13-9.13,23.74-9.13,32.87-1.83l84,63.92c11,7.31,11,20.09,3.65,29.22l-53,51.14c-58.44,58.44-206.37-49.31-252-95S3.51,160.12,62,101.68l51.14-51.14C120.4,41.42,135,43.24,140.48,52.37Z" />
  </g>,
  "0 0 512 512"
);

export const TurnConnectivityIcon = wrapSvgPath(
  <g>
    <path d="M430.89,48a45,45,0,0,0-37.75,69.48L311.68,192.2a91,91,0,0,0-109.33-10.8l-29-39.6a37,37,0,1,0-12.1,8.86l29,39.6A91.1,91.1,0,0,0,161,278.48l-55.63,17.1a37,37,0,1,0,4.56,14.29l55.46-17A91.23,91.23,0,0,0,191.74,329L157.2,378.34A45.07,45.07,0,1,0,169.48,387L204,337.61a91,91,0,0,0,96.31-2.86l50,62.1a41,41,0,1,0,11.52-9.61l-49.76-61.82a91,91,0,0,0,9.74-122.17l81.48-74.72A45,45,0,1,0,430.89,48Z" />
  </g>,
  "0 0 512 512"
);

export const LocationIco = wrapSvgPath(
  <g>
    <path d="M363.76,102.58h0a152.4,152.4,0,0,0-215.53,0h0a152.4,152.4,0,0,0-19.27,192l94,141.77a39.66,39.66,0,0,0,66.12,0l94-141.77A152.4,152.4,0,0,0,363.76,102.58ZM256,301a92,92,0,1,1,92-92A92,92,0,0,1,256,301Z" />
  </g>,
  "0 0 512 512"
);

export const BandwidthIco = wrapSvgPath(
  <g>
    <path d="M53,352.94a14.1,14.1,0,0,0,14-12.31,169.89,169.89,0,0,1,337.06,0,14.1,14.1,0,0,0,14,12.31h60a14.13,14.13,0,0,0,14.13-13.69q.14-4.71.09-9.47A235.74,235.74,0,0,0,255,96C125,96.52,19.7,202.12,19.7,332.3q0,3.46.1,6.89a14.15,14.15,0,0,0,14.13,13.75Z" />
    <path d="M188.89,408.08h0a27,27,0,0,1,1.65-39.77l117.71-99.6a14.2,14.2,0,0,1,19.21.8h0a14.2,14.2,0,0,1,.8,19.21l-99.6,117.71A27,27,0,0,1,188.89,408.08Z" />
  </g>,
  "0 0 512 512"
);

export const VideoBandwidthIco = wrapSvgPath(
  <g>
    <path d="M261.54,432.94a112.06,112.06,0,0,1-15.7-57.31q0-4.58.37-9.06c0-.19,0-.38,0-.57s0-.33,0-.5h0v-77h0a30.49,30.49,0,0,1,58.41-11.77,111.83,111.83,0,0,1,31.58-11.52V47.75A29.25,29.25,0,0,0,306.93,18.5H89.44A29.25,29.25,0,0,0,60.18,47.75V405.25A29.25,29.25,0,0,0,89.44,434.5H262.51C262.19,434,261.85,433.47,261.54,432.94ZM307.18,212a30.5,30.5,0,1,1-30.5-30.5A30.5,30.5,0,0,1,307.18,212ZM90.18,75.77A15.27,15.27,0,0,1,105.46,60.5H290.91a15.27,15.27,0,0,1,15.27,15.27v63.46a15.27,15.27,0,0,1-15.27,15.27H105.46a15.27,15.27,0,0,1-15.27-15.27Zm30.5,320.73a30.5,30.5,0,1,1,30.5-30.5A30.5,30.5,0,0,1,120.68,396.5Zm0-77a30.5,30.5,0,1,1,30.5-30.5A30.5,30.5,0,0,1,120.68,319.5Zm0-77a30.5,30.5,0,1,1,30.5-30.5A30.5,30.5,0,0,1,120.68,242.5Zm78,154a30.5,30.5,0,1,1,30.5-30.5A30.5,30.5,0,0,1,198.68,396.5Zm0-77a30.5,30.5,0,1,1,30.5-30.5A30.5,30.5,0,0,1,198.68,319.5Zm0-77a30.5,30.5,0,1,1,30.5-30.5A30.5,30.5,0,0,1,198.68,242.5Z" />
    <path d="M404.84,456.9c28.27-16.34,47.26-46.38,47.26-81.27a93.63,93.63,0,0,0-187.27,0c0,34.89,19,64.93,47.26,81.27l-23,30c-.88,1.32-1.33,3.09-.44,4.42s2.21,2.65,4,2.65H424.27a4.24,4.24,0,0,0,4-2.65c.88-1.32.44-3.09-.44-4.42Zm-46.38-22.46a58.8,58.8,0,1,1,58.8-58.8A59,59,0,0,1,358.47,434.44Zm24.2-69.54a14.45,14.45,0,0,1-11.66-22,3.68,3.68,0,0,0-2.12-5.53,39.05,39.05,0,1,0,27.54,27.54,3.68,3.68,0,0,0-5.53-2.12A14.39,14.39,0,0,1,382.67,364.9Z" />
  </g>,
  "0 0 512 512"
);

export const VideoQualityIco = wrapSvgPath(
  <g>
    <path d="M347.88,392.5c56-32.37,93.63-91.87,93.63-161C441.5,129.13,358.38,46,256,46S70.5,129.13,70.5,231.5c0,69.13,37.63,128.63,93.63,161L118.63,452c-1.75,2.63-2.62,6.13-.87,8.75s4.38,5.25,7.88,5.25H386.38q5.25,0,7.88-5.25c1.75-2.62.88-6.12-.87-8.75ZM256,348c-64,0-116.5-52.49-116.5-116.5S192,115,256,115s116.5,52.49,116.5,116.5S320,348,256,348Z" />
    <path d="M320.23,206a28.63,28.63,0,0,1-39.39-39.39,7.29,7.29,0,0,0-4.21-11,77.36,77.36,0,1,0,54.55,54.55A7.29,7.29,0,0,0,320.23,206Z" />
  </g>,
  "0 0 512 512"
);

export const VideoP2PIco = wrapSvgPath(
  <g>
    <path d="M108.26,262.33a70.51,70.51,0,1,0-70.51-70.51A70.72,70.72,0,0,0,108.26,262.33Z" />
    <path d="M197.71,321.55a65.18,65.18,0,0,0-62.42-46.41H81.22a65.18,65.18,0,0,0-62.42,46.41L7,360.83a23.18,23.18,0,0,0,22.2,29.86H187.31a23.18,23.18,0,0,0,22.2-29.86Z" />
    <path d="M403.74,262.33a70.51,70.51,0,1,0-70.51-70.51A70.72,70.72,0,0,0,403.74,262.33Z" />
    <path d="M505,360.83l-11.81-39.28a65.18,65.18,0,0,0-62.42-46.41H376.71a65.18,65.18,0,0,0-62.42,46.41l-11.81,39.28a23.18,23.18,0,0,0,22.2,29.86H482.8A23.18,23.18,0,0,0,505,360.83Z" />
    <path d="M311.84,252.41l-23.62-24.79a4.35,4.35,0,0,0-7.49,3V241H231.28V230.61a4.35,4.35,0,0,0-7.49-3l-23.62,24.79a5.21,5.21,0,0,0,0,7.19l23.62,24.79a4.35,4.35,0,0,0,7.49-3V271h49.44v10.39a4.35,4.35,0,0,0,7.49,3l23.62-24.79A5.21,5.21,0,0,0,311.84,252.41Z" />
  </g>,
  "0 0 512 512"
);

export const DnsLookupIco = wrapSvgPath(
  <g>
    <path d="M256.53,459.51a202.46,202.46,0,0,1-147.12-63.13,15,15,0,0,1,0-20.72,202.84,202.84,0,0,1,293.67.59,15,15,0,0,1,0,20.72,202.45,202.45,0,0,1-146.55,62.54ZM141.88,386.09a173.05,173.05,0,0,0,228.76.46,173.06,173.06,0,0,0-228.76-.46Z" />
    <path d="M404.24,117.46a15.05,15.05,0,0,0-1.13-1.35,202.84,202.84,0,0,0-293.67-.59,15.09,15.09,0,0,0-1.11,1.31A202.26,202.26,0,0,0,53,256c0,111.93,91.07,203,203,203s203-91.07,203-203A202.24,202.24,0,0,0,404.24,117.46ZM368.65,241a344.79,344.79,0,0,0-9.56-69.4,201.73,201.73,0,0,0,32.14-23.36A172.15,172.15,0,0,1,428.34,241Zm-285,0a172.16,172.16,0,0,1,37.55-93.31A201.67,201.67,0,0,0,153,171.06a344.4,344.4,0,0,0-9.7,69.93ZM227.33,94.41A55.33,55.33,0,0,1,241,86.08v82.61a171.52,171.52,0,0,1-52.06-12.93A200.72,200.72,0,0,1,200,130C208,114.76,217.41,102.46,227.33,94.41ZM271,86.08a55.33,55.33,0,0,1,13.67,8.33C294.59,102.46,304,114.76,312,130a201.07,201.07,0,0,1,11.24,26.15A171.67,171.67,0,0,1,271,168.77Zm57.27,12.75a173.54,173.54,0,0,1,42.3,27.65A173.47,173.47,0,0,1,350,142,203.86,203.86,0,0,0,328.27,98.82Zm-144.54,0a203.36,203.36,0,0,0-21.54,42.65A173.56,173.56,0,0,1,142,126,173.51,173.51,0,0,1,183.73,98.82Zm-3.08,85.88A200.85,200.85,0,0,0,241,198.78V241H173.37A318.9,318.9,0,0,1,180.66,184.7ZM241,271V425.89a55.33,55.33,0,0,1-13.67-8.33C217.41,409.51,208,397.21,200,382c-15.57-29.89-24.88-68.86-26.67-111Zm43.67,146.58A55.33,55.33,0,0,1,271,425.89V271h67.63c-1.79,42.14-11.1,81.12-26.67,111C304,397.21,294.59,409.51,284.67,417.57ZM271,241V198.85a200.87,200.87,0,0,0,60.43-13.77,319.16,319.16,0,0,1,7.2,55.9ZM83.66,271h59.69c2.24,57.76,17.37,108.13,40.39,142.16C128.64,387.71,89.11,334.15,83.66,271ZM328.27,413.15c23-34,38.14-84.4,40.39-142.16h59.69C422.89,334.15,383.36,387.71,328.27,413.15Z" />
  </g>,
  "0 0 512 512"
);

export const PingIco = wrapSvgPath(
  <g>
    <circle cx="256" cy="256" r="62.59" />
    <path d="M139.05,350.24a150.47,150.47,0,0,1,0-188.47,14.57,14.57,0,0,1,21.62-1.09l15,15a14.46,14.46,0,0,1,1.09,19.11,100.39,100.39,0,0,0,0,122.52,14.46,14.46,0,0,1-1.09,19.11l-15,15A14.57,14.57,0,0,1,139.05,350.24Z" />
    <path d="M351.33,351.33l-15-15a14.46,14.46,0,0,1-1.09-19.11,100.39,100.39,0,0,0,0-122.52,14.46,14.46,0,0,1,1.09-19.11l15-15a14.57,14.57,0,0,1,21.62,1.09,150.47,150.47,0,0,1,0,188.47A14.57,14.57,0,0,1,351.33,351.33Z" />
    <path d="M101.18,410.82a14.55,14.55,0,0,1-21.19-.6,234,234,0,0,1,0-308.43,14.55,14.55,0,0,1,21.19-.6L116,116a14.6,14.6,0,0,1,.67,19.89,184,184,0,0,0,0,240.3A14.6,14.6,0,0,1,116,396Z" />
    <path d="M410.82,410.82,396,396a14.6,14.6,0,0,1-.67-19.89,184,184,0,0,0,0-240.3A14.6,14.6,0,0,1,396,116l14.78-14.78a14.55,14.55,0,0,1,21.19.6,234,234,0,0,1,0,308.43A14.55,14.55,0,0,1,410.82,410.82Z" />
  </g>,
  "0 0 512 512"
);

export const ChevronIco = wrapSvgPath(
  <path
    fillRule="evenodd"
    d="M.381 13.129l5.892-5.617-5.969-5.69h.001a1.045 1.045 0 0
  1-.316-.745C-.011.485.493.005 1.114.005c.303 0 .578.115.78.301h.001l6.747 6.432h-.001c.212.195.345.469.345.772l-.001.002.001.003c0
  .303-.133.576-.345.771l.001.001-6.747 6.432-.003-.003a1.149 1.149 0 0 1-.778.299c-.621 0-1.125-.48-1.125-1.072 0-.326.152-.617.392-.814z"
  />,
  "0 0 9 15"
);

export const DownloadIcon = (props: any) => (
  <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
    <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </SvgIcon>
);

export const NetworkInformationIco = (props: any) => (
  <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24">
    <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </SvgIcon>
);

export const ExclamationMarkIcon = wrapSvgPath(
  <>
    <g>
      <path
        d="M15.167,0C6.805,0,0.001,6.804,0.001,15.167c0,8.362,6.804,15.167,15.166,15.167c8.361,0,15.166-6.805,15.166-15.167
		C30.333,6.804,23.528,0,15.167,0z M17.167,25.667h-4v-4.5h4V25.667z M17.167,19.542h-4V5.167h4V19.542z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </>,
  "0 0 30.334 30.334"
);
