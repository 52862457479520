import { Typography } from "@material-ui/core";
import React from "react";

const LoadingSpin = ({ classes }: { classes?: any }) => {
  return (
    <div className={classes.loadingSpin}>
      <svg
        width="34"
        height="36"
        viewBox="0 0 34 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="animate-spin"
      >
        <path
          d="M16.0002 2C18.1014 2 20.182 2.41385 22.1232 3.21793C24.0644 4.022 25.8282 5.20055 27.3139 6.68629C28.7997 8.17203 29.9782 9.93586 30.7823 11.8771C31.5864 13.8183 32.0002 15.8989 32.0002 18C32.0002 20.1012 31.5864 22.1817 30.7823 24.1229C29.9782 26.0641 28.7997 27.828 27.3139 29.3137C25.8282 30.7995 24.0644 31.978 22.1232 32.7821C20.182 33.5861 18.1014 34 16.0002 34C13.8991 34 11.8185 33.5861 9.8773 32.7821C7.93609 31.978 6.17226 30.7994 4.68652 29.3137"
          stroke="#3577C1"
          strokeWidth="4"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </svg>
      <Typography variant="body1">Loading your results...</Typography>
    </div>
  );
};

export default LoadingSpin;
