import { withTheme } from "@material-ui/core";
import React, { lazy, Suspense } from "react";
import { TestConfiguration } from "twillio-tests/core/testConfiguration";
import TestRtcFooter from "./TestRtcFooter";

interface FooterProps {
  darkMode?: boolean;
  config?: TestConfiguration;
}

const Footer = ({ darkMode, config }: FooterProps) => {
  const footerPath = config?.footer ? `./${config.footer}` : "./TestRtcFooter";
  let LazyComponent;

  if (footerPath === "./none") {
    return null;
  }

  try {
    LazyComponent = lazy(() => import(`${footerPath}`));
  } catch (error) {
    console.error("Dynamic import failed, falling back to default:", error);
    LazyComponent = TestRtcFooter;
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <LazyComponent darkMode={darkMode} />
    </Suspense>
  );
};

export default withTheme()(Footer);
