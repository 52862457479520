import * as React from "react";
import { connect } from "react-redux";

import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

import { format as formatDate } from "date-fns";
interface IPrintInfoBoxProps {
  classes?: any;
  createDate?: any;
  report?: string;
  uuid?: string;
}

const styles = (theme: any) => ({
  root: {
    marginTop: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 1,
  },
  message: {
    fontSize: 14,
    fontWeight: 600,
  },
});

function mapStateToProps(state: any) {
  return {
    theme: state.document.theme,
    createDate: state.tests.createDate,
    report: state.document.layout.report,
    uuid: state.document.uuid,
  };
}

const PrintInfoBox = (props: IPrintInfoBoxProps) => {
  const { classes, createDate, report, uuid } = props;
  if (!uuid || !report || !createDate) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Link href={`${window.location.origin}/${uuid}`}>
        <Typography className={classes.message}>Test ID: {uuid}</Typography>
      </Link>
      <Typography className={classes.message}>Date: {formatDate(new Date(createDate), "MMM d HH:mm")}</Typography>
    </div>
  );

};

export default connect(mapStateToProps, null)(withStyles(styles)(PrintInfoBox));
