import React from "react";
import "./Widget.css";
import { DarkErrorStateIcon, ErrorStateIcon } from "./Icons";
import { Typography, withStyles, withTheme } from "@material-ui/core";
import { colors } from "../../../../App/CustomTheme";

type ErrorMapping = {
  current: RegExp | string;
  newTitle: string;
  newError: string;
};

const errorMappings: ErrorMapping[] = [
  {
    current: "Test failed: Please keep your browser in the foreground during the test!",
    newTitle: "Focus please",
    newError: "Please keep your browser in the foreground during the test",
  },
  {
    current: "connectionOverrideIceServers=true, Missing connection RTCIceServers",
    newTitle: "Missing TURN servers",
    newError: "There are no TURN servers configured. This test cannot be conducted",
  },
  {
    current:
      "There was an error while establishing a connection using the OpenTok library. Please retry, and if the error persists, kindly contact our support team for assistance.",
    newTitle: "Connectivity issue",
    newError: "An OpenTok error. Please retry and if issue persists, contact our support team",
  },
  {
    current:
      "There was an network error while retrieving the connection information . Please retry, and if the error persists, kindly contact our support team for assistance",
    newTitle: "Connection retrieval",
    newError: "Failed to connect. Please retry and if issue persists, contact our support team",
  },
  {
    current: "Failed to get test credentials",
    newTitle: "Bad credentials",
    newError: "Failed to get proper test credentials",
  },
  {
    current: "Failed connecting to server, rtc-100:GetConnectionInfo",
    newTitle: "Can't connect",
    newError: "Failed connecting to server, rtc-100:GetConnectionInfo",
  },
  {
    current: "Failed connecting to server, GetConnectionInfoForGenesysLogin",
    newTitle: "Can't connect",
    newError: "Failed connecting to server, GetConnectionInfoForGenesysLogin",
  },
  {
    current: /^URL requested region (.*) unknown, valid regions are .*$/,
    newTitle: "Invalid region",
    newError: "Region {region} is unknown...",
  },
  {
    current: /^URL requested region \[\$\{.*\}\] found in datacenterRegionsMap \[\$\{.*\}\] but not in datacenterRegions$/,
    newTitle: "Region failure",
    newError: "Seems like a configuration error. Please contact our support team",
  },
  {
    current: "optimalServerByPingAll mode, not found ping results",
    newTitle: "Ping failure",
    newError: "Cannot find an optimal region because there are no ping results",
  },
  {
    current: "GetAwsOptimalServer: Missing CloudTestApi",
    newTitle: "Configuration issue",
    newError: "Missing CloudTestApi",
  },
  {
    current: /^GetAwsOptimalServer: query:\$\{.*\}\/country return empty$/,
    newTitle: "Configuration issue",
    newError: "Route 53 can't figure out an optimal region to use",
  },
  {
    current: /GetAwsOptimalServer \[(.*)\] not found in testConfig\.datacenterRegions/,
    newTitle: "Configuration issue",
    newError: "Route 53 has a region that isn't configured for this account",
  },
  {
    current: "Can't find location",
    newTitle: "Untracable",
    newError: "Can't find the location for this device",
  },
  {
    current: "Ping list not configured, use pairsList or allList",
    newTitle: "Configuration issue",
    newError: "There are no regions, so we can't perform ping test",
  },
  {
    current: "Could not establish a connection to server within 20 seconds",
    newTitle: "Connectivity issue",
    newError: "Cannot establish a connection to server within 20 seconds",
  },
  {
    current: "Could not establish a connection to server within 10 seconds",
    newTitle: "Connectivity issue",
    newError: "Cannot establish a connection to server within 10 seconds",
  },
  {
    current:
      "Sorry, your browser does not fully support WebRTC. Please update to a newer version of your browser, or switch to a browser that fully supports WebRTC",
    newTitle: "WebRTC support",
    newError: "Your browser doesn't support WebRTC. You'll need to use a different one",
  },
  {
    current: "publicTurn list is empty",
    newTitle: "Missing TURN servers",
    newError: "There are no TURN servers configured. This test cannot be conducted",
  },
  {
    current: "Cannot find servers to test",
    newTitle: "Missing TURN servers",
    newError: "There are no TURN servers configured. This test cannot be conducted",
  },
  {
    current: "Extension is not installed",
    newTitle: "Data not collected",
    newError: "To collect data, install our testRTC companion",
  },
];

const styles = (theme: any): any => ({
  wrapper: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "16px",
  },
  valuesContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "4px",
  },
  errorTitle: {
    color: theme.newPalette.text.primary,
  },
  errorValue: {
    textAlign: "center",
    color: theme.newPalette.text.secondary,
    padding: "0 16px 28px 16px",
    wordBreak: "break-word",
  },
  linkColor: {
    color: theme.newPalette.text.primary,
  },
});

const ErrorState = ({ error, classes, theme, currentTheme }: { error: string; classes: any; theme: any; currentTheme?: string }) => {
  const companionLink = "https://chromewebstore.google.com/detail/testrtc-companion/ninnkfgcogbifinffmalccnoijlhhnok?pli=1";

  const transformError = (error: string) => {
    for (const map of errorMappings) {
      let match;
      if (typeof map.current === "string") {
        if (map.current === error) {
          return {
            title: map.newTitle,
            error: map.newError,
          };
        }
      } else if ((match = new RegExp(map.current).exec(error))) {
        let dynamicError = map.newError;
        dynamicError = dynamicError.replace("{region}", match[1]);
        return {
          title: map.newTitle,
          error: dynamicError,
        };
      }
    }

    return {
      title: "Error",
      error: error,
    };
  };

  const transformedError = transformError(error);

  const renderErrorMessage = (errorMessage: string) => {
    if (errorMessage.includes("install our testRTC companion")) {
      const parts = errorMessage.split("install our testRTC companion");

      return (
        <>
          {parts[0]}
          <br />
          install our{" "}
          <a target="_blank" href={companionLink} className={`error-state-download-link ${classes.linkColor}`}>
            testRTC companion
          </a>
        </>
      );
    }
    return errorMessage;
  };

  const customColor = currentTheme !== undefined ? colors[currentTheme] : null;
  const colorPalette = theme?.newPalette.isDark ? customColor?.darkMode : customColor?.lightMode;

  return (
    <div className={classes.wrapper}>
      {theme.newPalette.isDark ? <DarkErrorStateIcon color={colorPalette?.background} /> : <ErrorStateIcon color={colorPalette?.background} />}
      <div className={classes.valuesContainer}>
        <Typography className={classes.errorTitle} variant="h3">
          {transformedError.title}
        </Typography>
        <Typography className={classes.errorValue} variant="body1">
          {renderErrorMessage(transformedError.error)}
        </Typography>
      </div>
    </div>
  );
};

export default withTheme()(withStyles(styles)(ErrorState));
