import React from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";

interface ProgressBarProps {
  range: [number, number] | null;
  currentValue: number | string;
  maxValue?: number;
  theme?: any;
  classes: any;
}

const styles = (theme: any): any => ({
  container: {
    width: "150px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    padding: "4px 0",
    color: theme.newPalette.isDark ? "#243544" : "rgba(255, 255, 255, 0.92)",
    fontSize: "10px",
  },
  barContainer: {
    position: "relative",
    height: "10px",
    display: "flex",
    borderRadius: "16px",
    overflow: "hidden",
  },
  redBar: {
    backgroundColor: theme.newPalette.error.main,
    height: "100%",
  },
  yellowBar: {
    backgroundColor: theme.newPalette.warning.main,
    height: "100%",
  },
  greenBar: {
    backgroundColor: theme.newPalette.success.main,
    height: "100%",
  },
  currentValueMarker: {
    width: "2px",
    height: "10px",
  },
  labelsContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "4px",
  },
  rangeLabel: {
    position: "absolute",
    transform: "translateX(-50%)",
  },
  currentValueLabel: {
    textAlign: "left",
    marginLeft: "-4px",
    fontWeight: 600,
  },
  currentValueContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
});

const ProgressTooltip: React.FC<ProgressBarProps> = ({ range, currentValue, maxValue = 100, theme, classes }) => {
  if (range === null) {
    return null;
  }
  const [start, end] = range;

  const getPercentage = (value: number) => (value / maxValue) * 100;

  const startPercentage = getPercentage(start);
  const endPercentage = getPercentage(end);
  const currentPercentage = getPercentage(Number(currentValue));

  const getMarkerColor = () => {
    if (currentPercentage < startPercentage) return theme.newPalette.error.main;
    if (currentPercentage < endPercentage) return theme.newPalette.warning.main;
    return theme.newPalette.success.main;
  };

  return (
    <div className={classes.container}>
      <div className={classes.currentValueContainer} style={{ marginLeft: `${currentPercentage}%` }}>
        <span className={classes.currentValueLabel} style={{ color: getMarkerColor() }}>
          {currentValue}
        </span>
        <div className={classes.currentValueMarker} style={{ backgroundColor: getMarkerColor() }} />
      </div>
      <div className={classes.barContainer}>
        <div className={classes.redBar} style={{ width: `${startPercentage}%` }} />
        <div className={classes.yellowBar} style={{ width: `${endPercentage - startPercentage}%` }} />
        <div className={classes.greenBar} style={{ width: `${100 - endPercentage}%` }} />
      </div>
      <div className={classes.labelsContainer}>
        <span>0</span>
        <span className={classes.rangeLabel} style={{ left: `${startPercentage}%` }}>
          {start}
        </span>
        <span className={classes.rangeLabel} style={{ left: `${endPercentage}%` }}>
          {end}
        </span>
        <span>{maxValue}</span>
      </div>
    </div>
  );
};

export default withTheme()(withStyles(styles)(ProgressTooltip));
