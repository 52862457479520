import React from "react";
import withTheme from "@material-ui/core/styles/withTheme";

interface IProps {
  mainColor?: string;
  data?: any;
  theme?: any;
}

const AdditionalTestErrorBox = (props: IProps) => {
  const { mainColor, data, theme } = props;

  const defaultProps = {
    mainColor,
    color: theme.customColors.successColor,
    errorColor: theme.customColors.errorColor,
  };

  const error = data?.error;

  return (
    <>
      {error && (
        <div className="tests-card-content tests-card-content--min additional-tests-card-content">
          <span id="ATEB-s-error" style={{ color: defaultProps.errorColor }}>
            {error}
          </span>
        </div>
      )}
    </>
  );
};

export default withTheme()(AdditionalTestErrorBox);
